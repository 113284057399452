@import "../../assets/stylesheets/variables";

.btn {
  @apply font-bold border-none rounded inline-flex cursor-pointer;

  &-cta {
    @apply inline-flex items-center text-white;
    background-color: $ptglobalRed;

    &:visited {
      @apply text-white;
    }

    &:hover {
      @apply text-white;
      background-color: $ptglobalLightRed;
    }

    &:active {
      @apply text-white;
      background-color: $ptglobalDarkRed;
    }
  }

  &-primary {
    @apply inline-flex items-center text-white;
    background-color: $ptglobalBlue;

    &:visited {
      @apply text-white;
    }

    &:hover {
      @apply text-white;
      background-color: lighten($ptglobalBlue, 5%);
    }

    &:active {
      @apply text-white;
      background-color: darken($ptglobalBlue, 5%);
    }
  }

  &-secondary {
    @apply bg-gray-600 inline-flex items-center text-white;
    background-color: $ptglobalGrey;

    &:visited {
      @apply text-white;
    }

    &:hover {
      @apply bg-gray-700 text-white;
      background-color: $ptglobalLightGrey;
    }

    &:active {
      @apply bg-gray-800 text-white;
      background-color: $ptglobalDarkGrey;
    }
  }
}
